"use client";

import { FC, useRef, type JSX } from "react";

import IconButton from "./icon-button";
import Tooltip from "./tooltip";

interface CountryButtonWithTooltipProps {
  ariaCurrent?: undefined | "page";
  ariaLabel: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  href: any;
  icon: JSX.Element;
  lang: string;
}
const CountryButtonWithTooltip: FC<CountryButtonWithTooltipProps> = ({
  ariaCurrent = undefined,
  ariaLabel,
  href,
  icon,
  lang,
}) => {
  const buttonRef = useRef<HTMLAnchorElement>(null);
  return (
    <li className="m-0 flex list-none flex-col items-center p-0">
      <IconButton
        ariaCurrent={ariaCurrent}
        ariaLabel={ariaLabel}
        href={href}
        icon={icon}
        lang={lang}
        ref={buttonRef}
      />
      {ariaCurrent && (
        <div className="country-indicator mt-3 h-1.5 w-3/5 rounded-full dark:bg-white" />
      )}
      <Tooltip anchorEl={buttonRef.current} title={ariaLabel} />
    </li>
  );
};
export default CountryButtonWithTooltip;
