"use client";

import { FC, useEffect, useRef, useState } from "react";

import { track } from "@vercel/analytics";

import dynamic from "next/dynamic";

import { usePreferredTheme } from "lib/utils";

import DarkModeSelectorLoader from "./dark-mode-loader";
import { IconButton } from "./icon-button";
import { Tooltip } from "./tooltip";

const Dark = dynamic(() => import("public/dark.svg"));
const Light = dynamic(() => import("public/light.svg"));

export interface DarkModeSelectorProps {
  translations: {
    darkMode: string;
    lightMode: string;
    showIn: string;
  };
}

export const DarkModeSelector: FC<DarkModeSelectorProps> = ({
  translations,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const t = translations;
  const { preferredTheme, setTheme } = usePreferredTheme();

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted ? (
    <>
      <IconButton
        ariaLabel={`${t.showIn} ${
          preferredTheme === "dark" ? t.lightMode : t.darkMode
        }`}
        className="size-[clamp(48px,_3rem,_70px)]"
        icon={preferredTheme === "dark" ? <Light /> : <Dark />}
        onClick={() => {
          track(`Theme`, {
            value: preferredTheme === "dark" ? "light" : "dark",
          });
          setTheme(preferredTheme === "dark" ? "light" : "dark");
        }}
        ref={buttonRef}
      />
      <Tooltip
        anchorEl={buttonRef.current}
        title={`${t.showIn} ${
          preferredTheme === "dark" ? t.lightMode : t.darkMode
        }`}
      />
    </>
  ) : (
    <DarkModeSelectorLoader />
  );
};
export default DarkModeSelector;
