"use client";
import React, { forwardRef, RefObject } from "react";

import Link from "next/link";

import { countriesType } from "lib/types";

/**
 * Props for the IconButton component.
 */
interface IconButtonProps
  extends React.HTMLAttributes<HTMLAnchorElement & HTMLButtonElement> {
  /**
   * Indicates whether the IconButton represents the current page.
   */
  ariaCurrent?: "page" | undefined;
  /**
   * A label that describes the purpose of the IconButton for accessibility purposes.
   */
  ariaLabel?: string;
  /**
   * Additional CSS classes to apply to the IconButton.
   */
  className?: string;
  /**
   * The URL to navigate to when the IconButton is clicked.
   */
  href?: string;
  hrefLang?: string;

  /**
   * The icon element to be rendered inside the IconButton.
   */
  icon?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  internalHref?: any;
  locale?: countriesType;
  /**
   * The source URL for the icon image.
   */
  src?: string;
  /**
   * Inline styles to apply to the IconButton.
   */
  style?: React.CSSProperties;
}
export const IconButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  IconButtonProps
>(
  (
    {
      ariaCurrent = undefined,
      ariaLabel,
      className,
      href,
      icon,
      internalHref,
      onClick,
      onMouseEnter,
      onMouseLeave,
      ...other
    },
    ref,
  ) => {
    return (href || internalHref) && !onClick ? (
      <Link
        aria-current={ariaCurrent}
        aria-describedby={undefined}
        aria-label={ariaLabel}
        className={`relative flex size-12 items-center overflow-hidden rounded-full border-transparent bg-beige font-semibold hover:brightness-90 focus-visible:brightness-95 dark:bg-slate-800 lg:size-12 forced-colors:border-2 [&>svg]:size-full ${
          className || ""
        }`}
        href={internalHref || href}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref as RefObject<HTMLAnchorElement | null>}
        title={undefined}
        {...other}
      >
        {icon}
      </Link>
    ) : (
      <button
        aria-describedby={undefined}
        aria-label={ariaLabel}
        className={`${
          className || ""
        } flex size-12 items-center rounded-full border-transparent bg-beige p-2 font-semibold transition-background hover:brightness-90 focus-visible:brightness-95 dark:bg-slate-800 lg:size-12 forced-colors:border-2 [&>svg]:size-full`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref as RefObject<HTMLButtonElement | null>}
        title={undefined}
        type="button"
        {...other}
      >
        {icon}
      </button>
    );
  },
);

export default IconButton;
